// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    BankAccount,
    BankAccountFromJSON,
    BankAccountToJSON,
    ClientCustomersLimitsInner,
    ClientCustomersLimitsInnerFromJSON,
    ClientCustomersLimitsInnerToJSON,
    Image,
    ImageFromJSON,
    ImageToJSON,
    Owner,
    OwnerFromJSON,
    OwnerToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface Client
 */
export interface Client  {
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    identifier?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    addressId?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    clientType?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    bankName?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    customerDefaultMonthlyLimit?: number;
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Image}
     * @memberof Client
     */
    logo?: Image;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    operatingAs?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    businessType?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    businessIncorporationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    businessRegistryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    promoCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    mailingAddressId?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    documentPrincipleSigningOfficer?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    documentArticlesOfIncorportation?: string;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Client
     */
    settlementVoidChequeOrAccountAuth?: UploadedFile;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Client
     */
    billingVoidChequeOrAccountAuth?: UploadedFile;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Client
     */
    settlementRecentBankStatement?: UploadedFile;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Client
     */
    billingRecentBankStatement?: UploadedFile;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    referredBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    referralCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    pendingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    documentPrincipleSigningOfficer2?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    invoiceEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    businessCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    settlementDelayDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    countryCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    trusted?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    plaidToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    statusDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    clientTotalDailyMaximumAmount?: number;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Client
     */
    certificateOfGoodStanding?: UploadedFile;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    qualified?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    partnership?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    platformId?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    approveDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    ownerFormUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    expectedMonthlyVolume?: number;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    expectedAverageTransactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    expectedAverageTransactionAmountRange?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    expectedCustomerCountRange?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    expectedMonthlyTransactionCountRange?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    expectedMonthlyTransactionAmountRange?: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    expectedMaximumTransactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    initialAgreementSigner?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    integration?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    integrationPaymentAccountSet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    countOwners?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    signupUserIsPrimaryOwner?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    primaryOwnerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    newAuthSettingsEnabled?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof Client
     */
    address?: Address;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Client
     */
    addressesAttributes?: Array<Address>;
    /**
     * 
     * @type {BankAccount}
     * @memberof Client
     */
    settlementBankAccount?: BankAccount;
    /**
     * 
     * @type {BankAccount}
     * @memberof Client
     */
    billingBankAccount?: BankAccount;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof Client
     */
    ownersAttributes?: Array<Owner>;
    /**
     * 
     * @type {Address}
     * @memberof Client
     */
    addressAttributes?: Address;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Client
     */
    permissions?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof Client
     */
    releaseHelpers?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    partnerLinkSignup?: boolean;
    /**
     * 
     * @type {Array<ClientCustomersLimitsInner>}
     * @memberof Client
     */
    customersLimits?: Array<ClientCustomersLimitsInner>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    position?: string;
    /**
     * 
     * @type {Role}
     * @memberof Client
     */
    userNotifications?: Role;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    veriffCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    operatingAddressIsRegisteredAddress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    hasThirdParty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    thirdPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    processingClientOnboardingId?: number;
    /**
     * 
     * @type {Client}
     * @memberof Client
     */
    processingClientData?: Client;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    hasProcessingDetails?: boolean;
}

export function ClientFromJSON(json: any): Client {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'businessName': !exists(json, 'business_name') ? undefined : json['business_name'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'clientType': !exists(json, 'client_type') ? undefined : json['client_type'],
        'bankName': !exists(json, 'bank_name') ? undefined : json['bank_name'],
        'customerDefaultMonthlyLimit': !exists(json, 'customer_default_monthly_limit') ? undefined : json['customer_default_monthly_limit'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'logo': !exists(json, 'logo') ? undefined : ImageFromJSON(json['logo']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'operatingAs': !exists(json, 'operating_as') ? undefined : json['operating_as'],
        'businessType': !exists(json, 'business_type') ? undefined : json['business_type'],
        'businessIncorporationNumber': !exists(json, 'business_incorporation_number') ? undefined : json['business_incorporation_number'],
        'businessRegistryId': !exists(json, 'business_registry_id') ? undefined : json['business_registry_id'],
        'promoCode': !exists(json, 'promo_code') ? undefined : json['promo_code'],
        'mailingAddressId': !exists(json, 'mailing_address_id') ? undefined : json['mailing_address_id'],
        'documentPrincipleSigningOfficer': !exists(json, 'document_principle_signing_officer') ? undefined : json['document_principle_signing_officer'],
        'documentArticlesOfIncorportation': !exists(json, 'document_articles_of_incorportation') ? undefined : json['document_articles_of_incorportation'],
        'settlementVoidChequeOrAccountAuth': !exists(json, 'settlement_void_cheque_or_account_auth') ? undefined : UploadedFileFromJSON(json['settlement_void_cheque_or_account_auth']),
        'billingVoidChequeOrAccountAuth': !exists(json, 'billing_void_cheque_or_account_auth') ? undefined : UploadedFileFromJSON(json['billing_void_cheque_or_account_auth']),
        'settlementRecentBankStatement': !exists(json, 'settlement_recent_bank_statement') ? undefined : UploadedFileFromJSON(json['settlement_recent_bank_statement']),
        'billingRecentBankStatement': !exists(json, 'billing_recent_bank_statement') ? undefined : UploadedFileFromJSON(json['billing_recent_bank_statement']),
        'referredBy': !exists(json, 'referred_by') ? undefined : json['referred_by'],
        'referralCode': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'pendingDate': !exists(json, 'pending_date') ? undefined : json['pending_date'],
        'documentPrincipleSigningOfficer2': !exists(json, 'document_principle_signing_officer_2') ? undefined : json['document_principle_signing_officer_2'],
        'invoiceEmail': !exists(json, 'invoice_email') ? undefined : json['invoice_email'],
        'businessCategory': !exists(json, 'business_category') ? undefined : json['business_category'],
        'settlementDelayDays': !exists(json, 'settlement_delay_days') ? undefined : json['settlement_delay_days'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'trusted': !exists(json, 'trusted') ? undefined : json['trusted'],
        'plaidToken': !exists(json, 'plaid_token') ? undefined : json['plaid_token'],
        'websiteUrl': !exists(json, 'website_url') ? undefined : json['website_url'],
        'statusDescription': !exists(json, 'status_description') ? undefined : json['status_description'],
        'clientTotalDailyMaximumAmount': !exists(json, 'client_total_daily_maximum_amount') ? undefined : json['client_total_daily_maximum_amount'],
        'certificateOfGoodStanding': !exists(json, 'certificate_of_good_standing') ? undefined : UploadedFileFromJSON(json['certificate_of_good_standing']),
        'qualified': !exists(json, 'qualified') ? undefined : json['qualified'],
        'partnership': !exists(json, 'partnership') ? undefined : json['partnership'],
        'platformId': !exists(json, 'platform_id') ? undefined : json['platform_id'],
        'approveDate': !exists(json, 'approve_date') ? undefined : json['approve_date'],
        'publicId': !exists(json, 'public_id') ? undefined : json['public_id'],
        'ownerFormUrl': !exists(json, 'owner_form_url') ? undefined : json['owner_form_url'],
        'expectedMonthlyVolume': !exists(json, 'expected_monthly_volume') ? undefined : json['expected_monthly_volume'],
        'expectedAverageTransactionAmount': !exists(json, 'expected_average_transaction_amount') ? undefined : json['expected_average_transaction_amount'],
        'expectedAverageTransactionAmountRange': !exists(json, 'expected_average_transaction_amount_range') ? undefined : json['expected_average_transaction_amount_range'],
        'expectedCustomerCountRange': !exists(json, 'expected_customer_count_range') ? undefined : json['expected_customer_count_range'],
        'expectedMonthlyTransactionCountRange': !exists(json, 'expected_monthly_transaction_count_range') ? undefined : json['expected_monthly_transaction_count_range'],
        'expectedMonthlyTransactionAmountRange': !exists(json, 'expected_monthly_transaction_amount_range') ? undefined : json['expected_monthly_transaction_amount_range'],
        'expectedMaximumTransactionAmount': !exists(json, 'expected_maximum_transaction_amount') ? undefined : json['expected_maximum_transaction_amount'],
        'initialAgreementSigner': !exists(json, 'initial_agreement_signer') ? undefined : json['initial_agreement_signer'],
        'integration': !exists(json, 'integration') ? undefined : json['integration'],
        'integrationPaymentAccountSet': !exists(json, 'integration_payment_account_set') ? undefined : json['integration_payment_account_set'],
        'countOwners': !exists(json, 'count_owners') ? undefined : json['count_owners'],
        'signupUserIsPrimaryOwner': !exists(json, 'signup_user_is_primary_owner') ? undefined : json['signup_user_is_primary_owner'],
        'primaryOwnerId': !exists(json, 'primary_owner_id') ? undefined : json['primary_owner_id'],
        'newAuthSettingsEnabled': !exists(json, 'new_auth_settings_enabled') ? undefined : json['new_auth_settings_enabled'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'addressesAttributes': !exists(json, 'addresses_attributes') ? undefined : (json['addresses_attributes'] as Array<any>).map(AddressFromJSON),
        'settlementBankAccount': !exists(json, 'settlement_bank_account') ? undefined : BankAccountFromJSON(json['settlement_bank_account']),
        'billingBankAccount': !exists(json, 'billing_bank_account') ? undefined : BankAccountFromJSON(json['billing_bank_account']),
        'ownersAttributes': !exists(json, 'owners_attributes') ? undefined : (json['owners_attributes'] as Array<any>).map(OwnerFromJSON),
        'addressAttributes': !exists(json, 'address_attributes') ? undefined : AddressFromJSON(json['address_attributes']),
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'releaseHelpers': !exists(json, 'release_helpers') ? undefined : json['release_helpers'],
        'partnerLinkSignup': !exists(json, 'partner_link_signup') ? undefined : json['partner_link_signup'],
        'customersLimits': !exists(json, 'customers_limits') ? undefined : (json['customers_limits'] as Array<any>).map(ClientCustomersLimitsInnerFromJSON),
        'position': !exists(json, 'position') ? undefined : json['position'],
        'userNotifications': !exists(json, 'user_notifications') ? undefined : RoleFromJSON(json['user_notifications']),
        'veriffCompleted': !exists(json, 'veriff_completed') ? undefined : json['veriff_completed'],
        'operatingAddressIsRegisteredAddress': !exists(json, 'operating_address_is_registered_address') ? undefined : json['operating_address_is_registered_address'],
        'hasThirdParty': !exists(json, 'has_third_party') ? undefined : json['has_third_party'],
        'thirdPartyId': !exists(json, 'third_party_id') ? undefined : json['third_party_id'],
        'processingClientOnboardingId': !exists(json, 'processing_client_onboarding_id') ? undefined : json['processing_client_onboarding_id'],
        'processingClientData': !exists(json, 'processing_client_data') ? undefined : ClientFromJSON(json['processing_client_data']),
        'hasProcessingDetails': !exists(json, 'has_processing_details') ? undefined : json['has_processing_details'],
    };
}

export function ClientToJSON(value?: Client): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'business_name': value.businessName,
        'identifier': value.identifier,
        'address_id': value.addressId,
        'phone': value.phone,
        'client_type': value.clientType,
        'bank_name': value.bankName,
        'customer_default_monthly_limit': value.customerDefaultMonthlyLimit,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'logo': ImageToJSON(value.logo),
        'status': value.status,
        'operating_as': value.operatingAs,
        'business_type': value.businessType,
        'business_incorporation_number': value.businessIncorporationNumber,
        'business_registry_id': value.businessRegistryId,
        'promo_code': value.promoCode,
        'mailing_address_id': value.mailingAddressId,
        'document_principle_signing_officer': value.documentPrincipleSigningOfficer,
        'document_articles_of_incorportation': value.documentArticlesOfIncorportation,
        'settlement_void_cheque_or_account_auth': UploadedFileToJSON(value.settlementVoidChequeOrAccountAuth),
        'billing_void_cheque_or_account_auth': UploadedFileToJSON(value.billingVoidChequeOrAccountAuth),
        'settlement_recent_bank_statement': UploadedFileToJSON(value.settlementRecentBankStatement),
        'billing_recent_bank_statement': UploadedFileToJSON(value.billingRecentBankStatement),
        'referred_by': value.referredBy,
        'referral_code': value.referralCode,
        'pending_date': value.pendingDate,
        'document_principle_signing_officer_2': value.documentPrincipleSigningOfficer2,
        'invoice_email': value.invoiceEmail,
        'business_category': value.businessCategory,
        'settlement_delay_days': value.settlementDelayDays,
        'country_code': value.countryCode,
        'trusted': value.trusted,
        'plaid_token': value.plaidToken,
        'website_url': value.websiteUrl,
        'status_description': value.statusDescription,
        'client_total_daily_maximum_amount': value.clientTotalDailyMaximumAmount,
        'certificate_of_good_standing': UploadedFileToJSON(value.certificateOfGoodStanding),
        'qualified': value.qualified,
        'partnership': value.partnership,
        'platform_id': value.platformId,
        'approve_date': value.approveDate,
        'public_id': value.publicId,
        'owner_form_url': value.ownerFormUrl,
        'expected_monthly_volume': value.expectedMonthlyVolume,
        'expected_average_transaction_amount': value.expectedAverageTransactionAmount,
        'expected_average_transaction_amount_range': value.expectedAverageTransactionAmountRange,
        'expected_customer_count_range': value.expectedCustomerCountRange,
        'expected_monthly_transaction_count_range': value.expectedMonthlyTransactionCountRange,
        'expected_monthly_transaction_amount_range': value.expectedMonthlyTransactionAmountRange,
        'expected_maximum_transaction_amount': value.expectedMaximumTransactionAmount,
        'initial_agreement_signer': value.initialAgreementSigner,
        'integration': value.integration,
        'integration_payment_account_set': value.integrationPaymentAccountSet,
        'count_owners': value.countOwners,
        'signup_user_is_primary_owner': value.signupUserIsPrimaryOwner,
        'primary_owner_id': value.primaryOwnerId,
        'new_auth_settings_enabled': value.newAuthSettingsEnabled,
        'address': AddressToJSON(value.address),
        'addresses_attributes': value.addressesAttributes === undefined ? undefined : (value.addressesAttributes as Array<any>).map(AddressToJSON),
        'settlement_bank_account': BankAccountToJSON(value.settlementBankAccount),
        'billing_bank_account': BankAccountToJSON(value.billingBankAccount),
        'owners_attributes': value.ownersAttributes === undefined ? undefined : (value.ownersAttributes as Array<any>).map(OwnerToJSON),
        'address_attributes': AddressToJSON(value.addressAttributes),
        'permissions': value.permissions,
        'release_helpers': value.releaseHelpers,
        'partner_link_signup': value.partnerLinkSignup,
        'customers_limits': value.customersLimits === undefined ? undefined : (value.customersLimits as Array<any>).map(ClientCustomersLimitsInnerToJSON),
        'position': value.position,
        'user_notifications': RoleToJSON(value.userNotifications),
        'veriff_completed': value.veriffCompleted,
        'operating_address_is_registered_address': value.operatingAddressIsRegisteredAddress,
        'has_third_party': value.hasThirdParty,
        'third_party_id': value.thirdPartyId,
        'processing_client_onboarding_id': value.processingClientOnboardingId,
        'processing_client_data': ClientToJSON(value.processingClientData),
        'has_processing_details': value.hasProcessingDetails,
    };
}


