import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Typekit from 'react-typekit'

import { PUBLIC_ROUTES, LOGGED_OUT_ROUTES, LOGGED_IN_ROUTES, STUDENT_ROUTE, CLIENT_ROUTE } from 'app/routes'

// Themes
import 'assets/scss/Saas.scss'
import { getActiveClient, getIsLoggedIn, getActiveUser, justReplace } from 'app/selectors'
import { Loading } from 'components/Loading'
import { useMutation, useRequest } from 'redux-query-react'
import { accountQuery } from 'app/queries'
import { getReleaseHelpers, reportError, getConstants } from 'api'
import { ScrollToTop } from 'components/ScrollToTop'
import { HelpScoutBeacon } from 'components/HelpScoutBeacon'

const App = () => {
  const filteredErrorMessages = ['Script error.']
  const activeUser = useSelector(getActiveUser) || {}
  const location = useLocation()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const activeClient = useSelector(getActiveClient)
  const userTypeRoute = activeUser?.customerId ? STUDENT_ROUTE : CLIENT_ROUTE
  const routes = (isLoggedIn ? [userTypeRoute].concat(LOGGED_IN_ROUTES) : LOGGED_OUT_ROUTES).concat(PUBLIC_ROUTES)
  let defaultPath = routes[0].path
  if (!isLoggedIn) {
    if (routes.some((v) => location.pathname.includes(v.path))) {
      defaultPath = `${routes[0].path}${location.pathname}`
    } else if (location.pathname !== defaultPath) {
      defaultPath = `${defaultPath}?after=${location.pathname}`
    }
  }

  const [accountRequestState, refreshAccount] = useRequest(isLoggedIn && accountQuery(true))

  const [releaseHelpersRequestState, refreshReleaseHelpers] = useRequest(
    getReleaseHelpers({
      transform: (body) => {
        return { releaseHelpers: body.releaseHelpers }
      },
      update: {
        releaseHelpers: justReplace,
      },
    }),
  )

  const [constantsRequestState, refreshConstants] = useRequest(
    getConstants({
      transform: (body) => {
        return { constants: body.collection }
      },
      update: {
        constants: justReplace,
      },
    }),
  )

  const [_handleErrorState, handleError] = useMutation(
    (event: ErrorEvent) =>
      isLoggedIn &&
      !filteredErrorMessages.includes(event.message) &&
      reportError({
        modelError: {
          message: event.message,
          path: location.pathname,
          activeClientId: activeClient?.id,
        },
      }),
  )

  useEffect(() => {
    const doHandleError = (event: ErrorEvent) => {
      event.preventDefault()
      handleError(event)
    }
    window.addEventListener('error', doHandleError)
    return () => window.removeEventListener('error', doHandleError)
  }, [activeClient, location])

  return (
    <div className="app">
      <ScrollToTop />
      <HelpScoutBeacon />
      <Typekit kitId="xri4kel" />
      <div className="wrapper">
        {accountRequestState.isPending || releaseHelpersRequestState.isPending || constantsRequestState.isPending ? (
          <Loading />
        ) : (
          <Switch>
            {routes.map(
              (route, index) =>
                route !== undefined && (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={({ match }) => route.component && <route.component {...match.params} />}
                  />
                ),
            )}
            <Redirect to={defaultPath} />
          </Switch>
        )}
      </div>
    </div>
  )
}

export default App
