import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getActiveUser, getIsLoggedIn } from 'app/selectors'

declare global {
  interface Window {
    Beacon: (method: string, parameters?: unknown) => void
  }
}
export const HelpScoutBeacon: React.FC = () => {
  const isLoggedIn = useSelector(getIsLoggedIn)
  const activeUser = useSelector(getActiveUser)
  const chatNotShownPaths = [
    '/authorize/',
    '/donation/',
    '/link_authorizations',
    '/email_authorizations',
    '/customer_authorizations',
    '/microdeposit_verifications',
  ]

  try {
    window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_ID)
  } catch (error) {
    console.error('Error initializing HelpScout Beacon:', error)
  }

  useEffect(() => {
    if (chatNotShownPaths.some((path) => window.location.pathname.includes(path))) {
      window.Beacon('destroy')
    }
  })

  useEffect(() => {
    if (isLoggedIn && activeUser) {
      window.Beacon('identify', { name: activeUser.name, email: activeUser.email })
      window.Beacon('show-message', process.env.REACT_APP_HELPSCOUT_CLOSURE_MESSAGE_ID)
    }
  }, [isLoggedIn, activeUser])

  return <></>
}
