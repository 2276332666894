import { applyPromoCode, Client, ClientOnboarding, markClientOnboardingGtmCompletedAccount, Owner } from 'api'
import { CLIENT_STATUS } from 'app/constants'
import { justUpdate } from 'app/selectors'
import appPreviewImage from 'assets/images/onboarding/Pre-authorized-debit-payment-backend-in-Rotessa.png'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import copy from 'clipboard-copy'
import { LoadingMaskCentered } from 'components/LoadingMask'
import { NotificationsText } from 'components/NotificationsText'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { requestAsync, updateEntities } from 'redux-query'
import { useMutation } from 'redux-query-react'
import { gtmEvent, isPermitted } from 'utilities'
import { filterSubSteps, OnboardingPageProps } from './onboardingManager'
import { TransitionFromThirdPartyComponent } from './thirdPartyComponents'

const triggerGTMAccountCompletedEvent = (activeClient: Client, clientOnboarding: ClientOnboarding, dispatch) => {
  if (
    activeClient?.id &&
    activeClient.clientType !== 'Third Party' &&
    clientOnboarding &&
    !clientOnboarding.gtmCompletedAccountTriggered
  ) {
    gtmEvent('completedAccount')
    dispatch(
      requestAsync(
        markClientOnboardingGtmCompletedAccount(
          { clientId: activeClient.id },
          {
            force: true,
            transform: (body) => {
              return { clientOnboarding: body.clientOnboarding }
            },
            update: { clientOnboarding: justUpdate },
          },
        ),
      ),
    )
  }
}

export const PendingRightPanel = () => {
  return (
    <>
      <Row>
        <Col lg={8}>
          <Card style={{ backgroundColor: '#e7e7e7', minWidth: '300px' }}>
            <CardBody>
              <img src={appPreviewImage} width={250} />
              <h3>In the meantime, we'd love to show you a personal demo</h3>
              <Button
                className="mt-2 demo-button d-flex align-items-center justify-content-center"
                tag="a"
                href="https://rotessa.com/demo/"
                target="_blank"
                color="blue"
              >
                BOOK A DEMO
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export const PendingComponent = (props: OnboardingPageProps) => {
  const dispatch = useDispatch()
  useEffect(() => triggerGTMAccountCompletedEvent(props.activeClient, props.clientOnboarding, dispatch), [])
  const [hasSubmittedPromoCode, setHasSubmittedPromoCode] = useState<boolean>(false)
  const [applyPromoCodeState, doApplyPromoCode] = useMutation((promoCode: string) =>
    applyPromoCode(
      {
        id: props.activeClient.id!,
        applyPromoCodeBody: {
          promoCode: promoCode,
        },
      },
      {
        transform: (body) => ({ ...body }),
      },
    ),
  )
  const handlePromoCodeSubmit = (event, values: { promoCode: string }) => {
    doApplyPromoCode(values.promoCode)?.then((response) => {
      props.setServerErrors(response?.transformed?.messages || {})
      if (response?.transformed?.status === 200) {
        dispatch(
          updateEntities({
            activeClient: (prev) => response?.transformed?.client || prev,
          }),
        )
        setHasSubmittedPromoCode(true)
      }
    })
  }

  if (props.activeClient?.clientType === 'Third Party') {
    return <TransitionFromThirdPartyComponent {...props} />
  }
  const approvalTime = props.activeClient?.countryCode === 'US' ? '2-5' : '2-4'

  return (
    <div>
      {applyPromoCodeState.isPending && <LoadingMaskCentered />}
      <Modal isOpen={hasSubmittedPromoCode}>
        <ModalHeader>Promo Code Added</ModalHeader>
        <ModalBody>
          <NotificationsText notifications={['The promo code has been added to your account.']} allowHTML />
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={() => setHasSubmittedPromoCode(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-title-box">
        <div className="d-flex align-items-center">
          <div className="completed-icon">
            <i className="mdi mdi-check-bold" />
          </div>
          <h3 className="ml-3 mb-0 mt-0">All done! Your account info has been submitted.</h3>
        </div>
        <p className="text-muted mt-2">
          Your information has been submitted to our compliance team and is pending approval. You can expect an email
          regarding your account approval in {approvalTime} business days.
        </p>

        {isPermitted('settings.add_promo_code', props.activeClient) && (
          <AvForm
            model={props.activeClient}
            onValidSubmit={handlePromoCodeSubmit}
            disabled={applyPromoCodeState.isPending}
            ref={props.formRef}
          >
            <h4 className="">Have a promo code?</h4>
            <p className="text-muted">
              If you are a Rotessa partner, or have been given a promo code by a Rotessa partner, enter it here.
            </p>
            <AvField
              name="promoCode"
              label="Promo Code"
              type="text"
              validate={{
                async: props.validate,
              }}
              onChange={props.handleFieldChange}
            />
            <Row>
              <Col xs={12} className="ml-auto mt-1 d-flex justify-content-end">
                <Button color="primary">Submit</Button>
              </Col>
            </Row>
          </AvForm>
        )}
      </div>
    </div>
  )
}

export const VeriffPendingComponent = (props: OnboardingPageProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (props.activeClient?.clientType !== 'Third Party') {
      props.setSteps((prev) =>
        prev.map((step) => {
          let newStep = { ...step }
          if (step.name === 'owners') {
            newStep.subSteps = [
              {
                label: 'Owner verification',
                name: 'owner_verification',
                status: 'pending',
              },
            ]
          }
          return newStep
        }),
      )
    }
    triggerGTMAccountCompletedEvent(props.activeClient, props.clientOnboarding, dispatch)
  }, [props.activeClient?.clientType])

  if (props.activeClient?.clientType === 'Third Party') {
    return <TransitionFromThirdPartyComponent {...props} />
  }

  const primaryOwner: Owner =
    props.activeClient?.ownersAttributes?.find((owner) => owner.id == props.activeClient?.primaryOwnerId) || {}
  const primaryThirdPartyOwner = props.activeThirdPartyClient?.ownersAttributes?.find(
    (owner) => owner.id == props.activeThirdPartyClient?.primaryOwnerId,
  )
  const link = `${window.location.host}/owner_signup/${props.activeClient?.ownerFormUrl || ''}`

  return (
    <div>
      <h3>We're still waiting on verification from {primaryOwner?.name}</h3>
      <p className="emphasized-content">
        In order to complete your account the primary owner needs to complete and submit their ID verification using the
        link below.
      </p>
      <Row>
        <Col className="align-items-center text-center">
          <InputGroup className="align-items-center text-center">
            <Input className="form-control" style={{ backgroundColor: 'white' }} readOnly value={link} />
            <Button
              onClick={() => {
                copy(link)
              }}
              color="blue"
              id="copy-auth-link-url-button"
              className="ml-1"
            >
              <i className="mdi mdi-content-copy mr-1 ml-1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col>
          <Badge
            className="text-center w-100 pt-1 pb-1"
            style={{ color: 'black', backgroundColor: 'rgba(197, 224, 244, 1)', fontSize: 16, fontWeight: 500 }}
          >
            Resend link to {primaryOwner?.name}
          </Badge>
        </Col>
      </Row>
      <p className="text-muted">
        You will be emailed when this step is complete and your account will be submitted for review.
      </p>

      {props.activeThirdPartyClient?.status === CLIENT_STATUS.created &&
        primaryThirdPartyOwner &&
        !primaryThirdPartyOwner.verified && (
          <Card style={{ backgroundColor: '#e7e7e7' }}>
            <CardBody>
              <ThirdPartyVeriffPendingComponent {...props} />
            </CardBody>
          </Card>
        )}
    </div>
  )
}

export const BankVerificationPendingComponent = ({ setSteps, activeClient, clientOnboarding }: OnboardingPageProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    setSteps((prev) =>
      prev.map((step) => {
        let newStep = { ...step }
        if (step.name === 'financial_info') {
          newStep.subSteps = filterSubSteps(step, ['pending', 'failed'])
        }
        return newStep
      }),
    )
    triggerGTMAccountCompletedEvent(activeClient, clientOnboarding, dispatch)
  }, [])

  return (
    <div>
      <div className="page-title-box">
        <h3>All done! Your account info has been submitted.</h3>
        <div className="d-flex align-items-center">
          <div className="pending-icon">
            <i className="mdi mdi-clock-outline" />
          </div>
          <p className="emphasized-content ml-3 mb-0">
            All your information is submitted and we're currently verifying your bank details electronically.
          </p>
        </div>
        <p className="text-muted mt-2">
          Once your bank information is successfully verified, your account will automatically be submitted for review
          to our compliance team. If we have problems verifying your bank information, you will be sent an email to
          complete this section again.
        </p>
      </div>
    </div>
  )
}

export const ThirdPartyVeriffPendingComponent = ({ clientOnboarding, activeThirdPartyClient }: OnboardingPageProps) => {
  const primaryThirdPartyOwner: Owner =
    activeThirdPartyClient?.ownersAttributes?.find((owner) => owner.id == activeThirdPartyClient?.primaryOwnerId) || {}
  const link = `${window.location.host}/owner_signup/${activeThirdPartyClient?.ownerFormUrl || ''}`
  const isThirdPartyVeriffPendingState = clientOnboarding.state === 'third_party_veriff_pending'

  return (
    <div>
      <h3>We're still waiting on verification from {primaryThirdPartyOwner?.name} on your third-party company</h3>
      {isThirdPartyVeriffPendingState && (
        <p className="emphasized-content">
          In order to complete your account the primary owner needs to complete and submit their ID verification using
          the link below.
        </p>
      )}
      <Row>
        <Col className="align-items-center text-center">
          <InputGroup className="align-items-center text-center">
            <Input className="form-control" style={{ backgroundColor: 'white' }} readOnly value={link} />
            <Button
              onClick={() => {
                copy(link)
              }}
              color="blue"
              id="copy-auth-link-url-button"
              className="ml-1"
            >
              <i className="mdi mdi-content-copy mr-1 ml-1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col>
          <Badge
            className="text-center w-100 pt-1 pb-1"
            style={{ color: 'black', backgroundColor: 'rgba(197, 224, 244, 1)', fontSize: 16, fontWeight: 500 }}
          >
            Resend link to {primaryThirdPartyOwner?.name}
          </Badge>
        </Col>
      </Row>
      {isThirdPartyVeriffPendingState && (
        <p className="text-muted">
          You will be emailed when this step is complete and your account will be submitted for review.
        </p>
      )}
    </div>
  )
}
