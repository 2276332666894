import {
  applyPromoCode,
  Client,
  ClientOnboarding,
  getClient,
  getClientsClientOnboarding,
  Owner,
  submitClientOnboardingState,
  User,
} from 'api'
import { logout } from 'app/authReducer'
import {
  ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY,
  CLIENT_STATUS,
  COUNTRY_CODES,
  SELECTABLE_CLIENT_TYPES,
} from 'app/constants'
import { getActiveClient, getClients, getEntities, justReplace, justUpdate } from 'app/selectors'
import rightArrow from 'assets/images/Right-Arrow-White.svg'
import logo from 'assets/images/logo.png'
import rotessaCornerBottomLeftImg from 'assets/images/onboarding/Rotessa-corner-bottom-left.png'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { LoadingMaskCentered, LoadingMaskNoBackground } from 'components/LoadingMask'
import { NotificationsText } from 'components/NotificationsText'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { updateEntities } from 'redux-query'
import { ActionPromise, useMutation, useRequest } from 'redux-query-react'
import { isPermitted } from 'utilities'
import {
  BusinessAddressComponent,
  BusinessCountryComponent,
  BusinessInfoComponent,
  BusinessNumbersComponent,
  BusinessPhoneComponent,
  BusinessTypeComponent,
  CertificateOfGoodStandingComponent,
} from './businessComponents'
import {
  BillingAccountComponent,
  BillingAccountRightPanel,
  SettlementAccountComponent,
  SettlementAccountRightPanel,
} from './financialComponents'
import {
  OwnershipComponent,
  OwnershipRightPanel,
  OwnersInfoComponent,
  OwnerVerificationComponent,
  PrimaryOwnerComponent,
} from './ownersComponents'
import {
  BankVerificationPendingComponent,
  PendingComponent,
  PendingRightPanel,
  ThirdPartyVeriffPendingComponent,
  VeriffPendingComponent,
} from './pendingComponents'
import { ThirdPartyComponent } from './thirdPartyComponents'
import {
  AverageTransactionAmountComponent,
  CustomerCountComponent,
  MaximumTransactionAmountComponent,
  MonthlyTransactionAmountComponent,
  MonthlyTransactionCountComponent,
} from './transactionQuestionComponents'
import {
  dynamicUserSubSteps,
  ReferredByComponent,
  UserComponent,
  WhoAreYouComponent,
  WhoAreYouRightPane,
} from './userComponents'

export interface OnboardingManagerProps {}

export interface OnboardingPageProps {
  onNext: (props: OnNextType) => ActionPromise<unknown>
  handleFieldChange: Function
  validate: Function
  serverErrors?: Errors
  setServerErrors: React.Dispatch<React.SetStateAction<Errors | undefined>>
  setCurrentPage: React.Dispatch<React.SetStateAction<OnboardingPageType | undefined>>
  setSteps: React.Dispatch<React.SetStateAction<StepType[]>>
  activeClient: Client
  doStateRefresh?: () => void
  clientOnboarding: ExtendedClientOnboardingType
  potentialThirdPartyClients?: Client[]
  activeThirdPartyClient?: Client
  processingClientOnboarding?: ClientOnboarding
  formRef?: React.MutableRefObject<AvForm>
}

export type OnNextType = {
  e?: any
  clientValues?: Client
  userValues?: User
  ownersValues?: Owner[]
  processingClientOnboardingValues?: ClientOnboarding
}

export type StepType = {
  label: string
  name: string
  subSteps?: StepType[]
  status?: string
}

export type RightPaneComponentType = {
  activeClient?: Client
  clientOnboarding?: ExtendedClientOnboardingType
  onNext: (OnNextType) => ActionPromise<unknown>
}

export type OnboardingPageType = {
  step: string
  state: string
  rightPane: JSX.Element | React.FunctionComponent<RightPaneComponentType>
  component: (props: OnboardingPageProps) => JSX.Element
}

export type ExtendedClientOnboardingType = ClientOnboarding & {
  stashedClientDetails?: Client
}

const thirdPartyPageVariants: OnboardingPageType[] = [
  {
    step: 'business_info',
    state: 'business_info',
    rightPane: <></>,
    component: BusinessInfoComponent,
  },
]

const signupPages: OnboardingPageType[] = [
  {
    step: 'user_info',
    state: 'referred_by',
    rightPane: <></>,
    component: ReferredByComponent,
  },
  {
    step: 'user_info',
    state: 'who_are_you',
    rightPane: WhoAreYouRightPane,
    component: WhoAreYouComponent,
  },
  {
    step: 'user_info',
    state: 'user_info',
    rightPane: <></>,
    component: UserComponent,
  },
  {
    step: 'third_party_client',
    state: 'third_party_client',
    rightPane: <></>,
    component: ThirdPartyComponent,
  },
  {
    step: 'third_party_client',
    state: 'third_party_veriff_pending',
    rightPane: PendingRightPanel,
    component: ThirdPartyVeriffPendingComponent,
  },
  {
    step: 'business_info',
    state: 'business_country',
    rightPane: <></>,
    component: BusinessCountryComponent,
  },
  {
    step: 'business_info',
    state: 'business_type',
    rightPane: <></>,
    component: BusinessTypeComponent,
  },
  {
    step: 'business_info',
    state: 'business_info',
    rightPane: (
      <>
        <h3>Why we ask</h3>
        <p>
          In order to verify your business we do background due diligence on the <em>legal business name</em> of your
          company. It is important to distinguish your <em>operating as name</em> or DBA (doing business as) from your
          legal name. The DBA is the name your customers know you by, and is what will show up on their bank statement.
        </p>
      </>
    ),
    component: BusinessInfoComponent,
  },
  {
    step: 'business_info',
    state: 'business_phone',
    rightPane: <></>,
    component: BusinessPhoneComponent,
  },
  {
    step: 'business_info',
    state: 'business_addresses',
    rightPane: (
      <>
        <h3>Why this is important</h3>
        <p>
          Your registered business address might be the same as your physical address. But for our purposes we want to
          verify that the <em>registered business address</em> that comes up in your business verification matches the
          information you provide here.
        </p>
      </>
    ),
    component: BusinessAddressComponent,
  },
  {
    step: 'business_info',
    state: 'certificate_of_good_standing',
    rightPane: (
      <>
        <h3>Why this is important</h3>
        <p>
          As part of our approval process, our banking partner requires evidence that you are in good standing with the
          Secretary of State. <br />
          <br />
          <div className="why-we-ask-subtext">
            <i>Some states provide your COGS for free online, and others will require a small fee to access.</i>
          </div>
        </p>
      </>
    ),
    component: CertificateOfGoodStandingComponent,
  },
  {
    step: 'business_info',
    state: 'business_numbers',
    rightPane: <></>,
    component: BusinessNumbersComponent,
  },
  {
    step: 'owners',
    state: 'owners',
    rightPane: OwnershipRightPanel,
    component: OwnershipComponent,
  },
  {
    step: 'owners',
    state: 'owners_info',
    rightPane: <></>,
    component: OwnersInfoComponent,
  },
  {
    step: 'owners',
    state: 'primary_owner',
    rightPane: (
      <>
        <h3>Why we ask</h3>
        <p>
          We aim to electronically verify all beneficial owners, but we do enhanced verification on the primary
          beneficial owner who has the highest stake in the company.
        </p>
      </>
    ),
    component: PrimaryOwnerComponent,
  },
  {
    step: 'owners',
    state: 'owner_verification',
    rightPane: <></>,
    component: OwnerVerificationComponent,
  },
  {
    step: 'financial_info',
    state: 'settlement_account',
    rightPane: SettlementAccountRightPanel,
    component: SettlementAccountComponent,
  },
  {
    step: 'financial_info',
    state: 'billing_account',
    rightPane: BillingAccountRightPanel,
    component: BillingAccountComponent,
  },
  {
    step: 'financial_info',
    state: 'average_transaction_amount',
    rightPane: (
      <p>
        If you require the ability to process more than $5,000 per transaction, our compliance may reach out to discuss
        your limits in more detail.
      </p>
    ),
    component: AverageTransactionAmountComponent,
  },
  {
    step: 'financial_info',
    state: 'customer_count',
    rightPane: <></>,
    component: CustomerCountComponent,
  },
  {
    step: 'financial_info',
    state: 'monthly_transaction_count',
    rightPane: <></>,
    component: MonthlyTransactionCountComponent,
  },
  {
    step: 'financial_info',
    state: 'monthly_transaction_amount',
    rightPane: <></>,
    component: MonthlyTransactionAmountComponent,
  },
  {
    step: 'financial_info',
    state: 'maximum_transaction_amount',
    rightPane: <></>,
    component: MaximumTransactionAmountComponent,
  },
  {
    step: 'owners',
    state: 'veriff_pending',
    rightPane: PendingRightPanel,
    component: VeriffPendingComponent,
  },
  {
    step: 'financial_info',
    state: 'bank_verification_pending',
    rightPane: PendingRightPanel,
    component: BankVerificationPendingComponent,
  },
  {
    step: 'pending',
    state: 'pending',
    rightPane: PendingRightPanel,
    component: PendingComponent,
  },
]

export const NextButton = ({ text = 'Next', disabled = false }) => {
  return (
    <Row>
      <Col xs={12} className="ml-auto mt-3 d-flex justify-content-end">
        <Button
          className="next-button d-flex align-items-center justify-content-center"
          color="primary"
          disabled={disabled}
        >
          {text}
          <img src={rightArrow} className="ml-1" />
        </Button>
      </Col>
    </Row>
  )
}

const generatePage = (activeClient: Client, clientOnboarding: ExtendedClientOnboardingType) => {
  return activeClient?.clientType === 'Third Party' &&
    thirdPartyPageVariants.find((page) => page.state === clientOnboarding.state)
    ? thirdPartyPageVariants.find((page) => page.state === clientOnboarding.state)
    : signupPages.find((page) => page.state === clientOnboarding.state)
}

const generateSteps = (
  clientOnboarding: ExtendedClientOnboardingType,
  activeClient: Client,
  account: User,
  activeThirdPartyClient: Client,
  fromCreateAccount: boolean,
  clients: Client[],
) => {
  return [
    {
      label: 'User Information',
      name: 'user_info',
      subSteps: dynamicUserSubSteps(account, clientOnboarding, clients),
    },
    ...(activeClient.thirdPartyId ||
    clientOnboarding.stashedClientDetails?.hasThirdParty ||
    activeClient.clientType === 'Third Party'
      ? [
          {
            label:
              (activeThirdPartyClient?.businessName || activeClient.businessName || 'Your Business') + ' Information',
            name: 'third_party_client',
            subSteps:
              clientOnboarding.state === 'third_party_veriff_pending' ||
              (activeClient.clientType === 'Third Party' && clientOnboarding.state === 'veriff_pending') ||
              activeThirdPartyClient?.status === 'Created'
                ? [
                    {
                      label: 'Owner verification',
                      name: 'third_party_veriff_pending',
                      status: 'pending',
                    },
                  ]
                : [],
          },
        ]
      : []),
    {
      label: 'Business Information',
      name: 'business_info',
      subSteps: [
        ...(fromCreateAccount && clientOnboarding.stashedClientDetails?.countryCode
          ? []
          : [{ label: 'Country', name: 'business_country', status: clientOnboarding.statuses?.business_country }]),
        { label: 'Type', name: 'business_type', status: clientOnboarding.statuses?.business_type },
        { label: 'Business information', name: 'business_info', status: clientOnboarding.statuses?.business_info },
        { label: 'Additional information', name: 'business_phone', status: clientOnboarding.statuses?.business_phone },
        { label: 'Addresses', name: 'business_addresses', status: clientOnboarding.statuses?.business_addresses },
        ...(activeClient?.countryCode === COUNTRY_CODES['american'] ||
        clientOnboarding.stashedClientDetails?.countryCode === COUNTRY_CODES['american']
          ? [
              {
                label: 'Certificate of Good Standing',
                name: 'certificate_of_good_standing',
                status: clientOnboarding.statuses?.certificate_of_good_standing,
              },
            ]
          : []),
        { label: 'Business numbers', name: 'business_numbers', status: clientOnboarding.statuses?.business_numbers },
      ],
    },
    {
      label: 'Owner Information',
      name: 'owners',
      subSteps: [
        { label: 'Ownership', name: 'ownership', status: clientOnboarding.statuses?.owners },
        {
          label: activeClient.businessType === 'Proprietorship' ? 'Name of owner' : 'Names of owners',
          name: 'owners_names',
          status: clientOnboarding.statuses?.owners,
        },
        { label: 'Primary owner', name: 'primary_owner', status: clientOnboarding.statuses?.primary_owner },
        {
          label: 'Owner verification',
          name: 'owner_verification',
          status: clientOnboarding.statuses?.owner_verification,
        },
      ],
    },
    {
      label: 'Financial Information',
      name: 'financial_info',
      subSteps: [
        {
          label: 'Settlement account',
          name: 'settlement_account',
          status: clientOnboarding.statuses?.settlement_account,
        },
        {
          label: 'Billing account',
          name: 'billing_account',
          status: clientOnboarding.statuses?.billing_account,
        },
        {
          label: 'Transaction information',
          name: 'transaction_questions',
          status: clientOnboarding.statuses?.transaction_questions,
        },
      ],
    },
  ]
}

export const filterSubSteps = (step: StepType, statusFilters: string[]) => {
  return step.subSteps?.filter((subStep) => statusFilters.includes(subStep.status || ''))
}

export const OnboardingManager = (props: OnboardingManagerProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const formRef = useRef<AvForm>()
  const account: User = useSelector((state) => getEntities(state).account)
  const clientOnboarding: ExtendedClientOnboardingType =
    useSelector((state) => getEntities(state).clientOnboarding) || {}
  const clients: Client[] = useSelector(getClients) || []
  const fromCreateAccount: boolean = useSelector((state) => getEntities(state).fromCreateAccount)
  const processingClientOnboarding: ClientOnboarding = useSelector(
    (state) => getEntities(state).processingClientOnboarding,
  )
  const activeClient: Client =
    useSelector(getActiveClient) || clients.find((client) => client.id == clientOnboarding.thirdPartyId) || {}
  const activeThirdPartyClient: Client = useSelector((state) => getEntities(state).activeThirdPartyClient)
  const completeClients = clients.filter(
    (client) =>
      ![CLIENT_STATUS.created, CLIENT_STATUS.pending].includes(client.status!) &&
      (SELECTABLE_CLIENT_TYPES.includes(client.clientType!) || client.hasProcessingDetails),
  )
  const potentialThirdPartyClients = clients.filter(
    (client) =>
      ![CLIENT_STATUS.closed, CLIENT_STATUS.created].includes(client.status!) &&
      (client.clientType === 'Third Party' || client.clientType === 'Processing') &&
      client.processingClientOnboardingId === undefined,
  )

  const [currentPage, setCurrentPage] = useState<OnboardingPageType | undefined>()
  const [steps, setSteps] = useState<StepType[]>(
    generateSteps(clientOnboarding, activeClient, account, activeThirdPartyClient, fromCreateAccount, clients),
  )
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false)
  const sessionClientOnboardingId = parseInt(sessionStorage.getItem(ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY) || '')
  const [hasSubmittedPromoCode, setHasSubmittedPromoCode] = useState<boolean>(false)

  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)

  useClearEntitiesOnUnmount(['processingClientOnboarding'])

  useEffect(() => {
    if (
      (clientOnboarding === undefined || Object.keys(clientOnboarding).length === 0) &&
      (activeClient === undefined || Object.keys(activeClient).length === 0)
    ) {
      // On initial load if no client yet set to referred_by state
      dispatch(
        updateEntities({
          clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
            ...prev,
            state: 'referred_by',
            statuses: {},
            stashedClientDetails: {},
          }),
        }),
      )
    }
  }, [clientOnboarding, activeClient])

  const [getOnboardingRequestState, doGetOnboardingRequest] = useRequest(
    activeClient?.id && activeClient.id > 0
      ? getClientsClientOnboarding(
          {
            clientId: activeClient.id,
            processingClientOnboardingId: sessionClientOnboardingId,
          },
          {
            force: true,
            transform: (body) => {
              return {
                clientOnboarding: body.clientOnboarding,
                activeClient: body.client,
                processingClientOnboarding: body.processingClientOnboarding || {},
              }
            },
            update: {
              clientOnboarding: justUpdate,
              activeClient: justReplace,
              processingClientOnboarding: justReplace,
            },
          },
        )
      : null,
  )

  const [getactiveThirdPartyClient, doGetactiveThirdPartyClient] = useRequest(
    activeClient?.thirdPartyId
      ? getClient(
          { id: activeClient.thirdPartyId },
          {
            transform: (body) => {
              return { activeThirdPartyClient: body?.client }
            },
            update: { activeThirdPartyClient: justReplace },
          },
        )
      : null,
  )

  const [saveMutationState, doSaveMutation] = useMutation(
    (
      clientValues: Client,
      userValues: User,
      ownersValues: Owner[],
      processingClientOnboardingValues?: ClientOnboarding,
    ) =>
      submitClientOnboardingState(
        {
          submitStateRequest: {
            processingClientOnboarding: { ...processingClientOnboarding, ...processingClientOnboardingValues },
            clientId: activeClient?.id,
            client: clientValues,
            user: userValues,
            owners: ownersValues,
            currentState: clientOnboarding.state,
          },
        },
        {
          transform: (body) => ({ ...body }),
        },
      ),
  )

  const [applyPromoCodeState, doApplyPromoCode] = useMutation((promoCode: string) =>
    applyPromoCode(
      {
        id: activeClient.id!,
        applyPromoCodeBody: {
          promoCode: promoCode,
        },
      },
      {
        transform: (body) => ({ ...body }),
      },
    ),
  )

  const handleNext = (props: OnNextType): ActionPromise<unknown> => {
    let promise: any = undefined
    if (!saveMutationState.isPending && !getOnboardingRequestState.isPending) {
      promise = doSaveMutation(
        props.clientValues || {},
        props.userValues || {},
        props.ownersValues || [],
        props.processingClientOnboardingValues,
      )

      promise?.then((response) => {
        setServerErrors(response?.transformed?.messages || {})
        dispatch(
          updateEntities({
            clientOnboarding: (prev: object) => ({ ...prev, ...response?.transformed?.clientOnboarding }),
            activeClient: (prev) => response?.transformed?.client || prev,
            account: (prev) => response?.transformed?.user || prev,
            processingClientOnboarding: (prev: ClientOnboarding) => ({
              ...prev,
              ...response?.transformed?.processingClientOnboarding,
            }),
          }),
        )

        if (response?.transformed?.processingClientOnboarding?.id) {
          sessionStorage.setItem(
            ACTIVE_PROCESSING_CLIENT_ONBOARDING_KEY,
            response.transformed.processingClientOnboarding.id,
          )
        }
      })
    }
    return promise
  }

  const handlePromoCodeSubmit = (event, values: { promoCode: string }) => {
    doApplyPromoCode(values.promoCode)?.then((response) => {
      setServerErrors(response?.transformed?.messages || {})
      if (response?.transformed?.status === 200) {
        dispatch(
          updateEntities({
            activeClient: (prev) => response?.transformed?.client || prev,
          }),
        )
        setShowPromoCodeModal(false)
        setHasSubmittedPromoCode(true)
      }
    })
  }

  const handleExit = () => {
    if (completeClients.length > 0) {
      let clientToRedirectTo =
        completeClients.find((client) => client.status === CLIENT_STATUS.approved) || completeClients[0]
      dispatch(
        updateEntities({
          activeClient: (_) => clientToRedirectTo,
          clientOnboarding: () => undefined,
        }),
      )
      history.push('/')
    } else {
      dispatch(logout())
    }
  }

  const getStepClassName = (step: StepType) => {
    let statusClass = currentPageForSteps?.step === step.name ? 'active' : ''
    if (
      (currentPageForSteps?.state === 'third_party_client' && step.name !== 'user_info') ||
      (step.name === 'third_party_client' && currentPageForSteps?.step === 'user_info')
    ) {
      return statusClass
    }

    if (step.subSteps?.every((substep) => substep.status === 'complete')) {
      statusClass += ' complete'
    }
    return statusClass
  }

  useEffect(() => {
    setCurrentPage(generatePage(activeClient, clientOnboarding))
    setSteps(generateSteps(clientOnboarding, activeClient, account, activeThirdPartyClient, fromCreateAccount, clients))
  }, [clientOnboarding.state, clientOnboarding.stashedClientDetails, activeClient.clientType, activeThirdPartyClient])

  let exitBtnText = activeClient?.id ? 'Save and ' : 'Cancel and '
  exitBtnText += completeClients && completeClients.length > 0 ? 'Exit' : 'Logout'

  let displayPromoCode =
    activeClient?.id &&
    activeClient.id > 0 &&
    activeClient.status === 'Created' &&
    activeClient.clientType !== 'Third Party' &&
    currentPage?.step !== 'pending' &&
    isPermitted('settings.add_promo_code', activeClient)

  const currentPageForSteps =
    activeClient.clientType === 'Third Party'
      ? signupPages.find((page) => page.state === 'third_party_client')
      : currentPage

  return (
    <div className="onboarding onboarding-manager">
      <Modal id="promoCodeModal" isOpen={showPromoCodeModal}>
        {applyPromoCodeState.isPending && <LoadingMaskCentered />}
        <AvForm model={activeClient} onValidSubmit={handlePromoCodeSubmit} disabled={saveMutationState.isPending}>
          <ModalHeader>
            <h4 className="mt-0 mb-0">Have a promo code?</h4>
          </ModalHeader>
          <ModalBody>
            <p>If you are a Rotessa partner, or have been given a promo code by a Rotessa partner, enter it here.</p>
            <AvField
              name="promoCode"
              label="Promo Code"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: t('required_field'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-rotessa-secondary"
              onClick={() => {
                setShowPromoCodeModal(false)
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" className="btn-rotessa-primary">
              Submit
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <Modal isOpen={hasSubmittedPromoCode}>
        <ModalHeader>Promo Code Added</ModalHeader>
        <ModalBody>
          <NotificationsText notifications={['The promo code has been added to your account.']} allowHTML />
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={() => setHasSubmittedPromoCode(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Row noGutters>
        <Col lg={4} className="onboarding-left-pane">
          <div className="onboarding-logo">
            <img src={logo} alt="Rotessa" height="25" />
          </div>
        </Col>
        <Col className="onboarding-top-bar mt-2 mt-lg-3">
          <Row className="d-none d-lg-flex">
            <Col className="text-right ">
              {activeClient.operatingAs && (
                <>
                  <div className="business-name">
                    {activeClient.operatingAs || activeClient.businessName || 'New Business'}
                  </div>
                  <div className="business-name">|</div>
                </>
              )}
              <Button className="btn-rotessa-tertiary" onClick={handleExit}>
                {exitBtnText}
              </Button>
            </Col>
          </Row>
          <Row className="d-lg-none">
            {completeClients && completeClients.length > 0 && (
              <Col>
                <div className="business-name">{activeClient.operatingAs || 'New Business'}</div>
              </Col>
            )}
            <Col className="text-right">
              <Button className="btn-rotessa-tertiary" onClick={handleExit}>
                {exitBtnText}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col lg={4} className="onboarding-left-pane d-flex flex-column justify-content-between">
          <div className="onboarding-steps d-none d-lg-flex mt-4">
            <ol>
              {steps.map((step) => {
                return (
                  <>
                    <li key={`${step.name}_step`} className={getStepClassName(step)}>
                      {step.label}
                      {currentPageForSteps?.step === step.name ? (
                        <ul>
                          {step.subSteps?.map((subStep) => {
                            return (
                              <li
                                key={`${step.name}_sub_step_${subStep.name}`}
                                className={`${currentPageForSteps.state === subStep.name ? 'active' : ''} ${
                                  subStep.status
                                }`}
                              >
                                {subStep.label}
                              </li>
                            )
                          })}
                        </ul>
                      ) : (
                        <>
                          {(filterSubSteps(step, ['pending', 'failed'])?.length || 0) > 0 && (
                            <ul>
                              {filterSubSteps(step, ['pending', 'failed'])?.map((subStep) => {
                                return (
                                  <li key={`${step.name}_sub_step_${subStep.name}`} className={`${subStep.status}`}>
                                    <span className="inactive">{subStep.label}</span>
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </>
                      )}
                    </li>
                  </>
                )
              })}
            </ol>
          </div>
          <div className="d-none d-lg-flex justify-content-center mt-1">
            <img src={rotessaCornerBottomLeftImg} width={300} />
          </div>
          <div className="d-none d-lg-flex justify-content-center p-4 onboarding-promo-code">
            {displayPromoCode && (
              <Button color="tertiary" onClick={() => setShowPromoCodeModal(true)}>
                <span className="text-white">
                  <i className="mdi mdi-tag mr-1" /> Have a promo code?
                </span>
              </Button>
            )}
          </div>
        </Col>
        <Col lg={4} className="onboarding-middle-pane">
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          {(getOnboardingRequestState.isPending || saveMutationState.isPending) && (
            <div className="position-relative w-100%">
              <LoadingMaskNoBackground />
            </div>
          )}
          {activeClient?.status !== 'Pending' && (
            <div className="d-lg-none text-muted">
              Part {steps.findIndex((step) => step.name === currentPage?.step) + 1} of {steps.length}
            </div>
          )}
          {currentPage?.component && (
            <currentPage.component
              onNext={handleNext}
              serverErrors={serverErrors}
              setServerErrors={setServerErrors}
              handleFieldChange={handleFieldChange}
              validate={validate}
              clientOnboarding={clientOnboarding}
              setSteps={setSteps}
              setCurrentPage={setCurrentPage}
              activeClient={activeClient}
              doStateRefresh={doGetOnboardingRequest}
              potentialThirdPartyClients={potentialThirdPartyClients}
              activeThirdPartyClient={activeThirdPartyClient}
              processingClientOnboarding={processingClientOnboarding}
              formRef={formRef}
            />
          )}
        </Col>
        <Col lg={4} className="onboarding-right-pane">
          <div className="right-pane-content">
            {typeof currentPage?.rightPane === 'function' ? (
              <currentPage.rightPane
                activeClient={activeClient}
                clientOnboarding={clientOnboarding}
                onNext={handleNext}
              />
            ) : (
              currentPage?.rightPane
            )}
          </div>
        </Col>
        {displayPromoCode && (
          <Col className="d-lg-none onboarding-promo-code">
            <Button color="tertiary" className="ml-2" onClick={() => setShowPromoCodeModal(true)}>
              <i className="mdi mdi-tag mr-1" /> Have a promo code?
            </Button>
          </Col>
        )}
      </Row>
    </div>
  )
}
