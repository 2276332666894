import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useRequest } from 'redux-query-react'

import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Fade, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { Client, applyPromoCode, getClient, updateClient, updateClientLogo } from 'api'
import { MAX_FILE_SIZE } from 'app/constants'
import { getActiveClient, getEntities } from 'app/selectors'
import { CenterContent } from 'components/CenterContent'
import { ExternalLink } from 'components/ExternalLink'
import { FileUploader, getFileErrorMessages } from 'components/FileUploader'
import { FormServerErrors } from 'components/FormServerErrors'
import { InfoPopover } from 'components/InfoPopover'
import { Loading } from 'components/Loading'
import { LoadingMaskCentered } from 'components/LoadingMask'
import { NotificationsText } from 'components/NotificationsText'
import { PostalCodeField } from 'components/PostalCodeField'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import NotificationSettingsCard from 'features/notification_settings/notificationSettingsCard'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { t } from 'i18next'
import { updateEntities } from 'redux-query'
import { formatCurrency, formatDateLong, isPermitted, isReleased, validatePhone } from 'utilities'
import { ApiKeysTable } from '../apiKeys/apiKeysView'
import { InvoicesTable } from '../invoices/invoicesTable'
import { UsersTable } from '../users/usersView'

export const BusinessInfoForm = () => {
  const dispatch = useDispatch()
  const [isPending, setIsPending] = useState(false)
  const [uploadErrors, setUploadErrors] = useState([] as string[])
  const activeClient = useSelector(getActiveClient) || {}
  const editClient: Client = useSelector((state) => getEntities(state).editClient) || {}
  const [logoFile, setLogoFile] = useState<File>()
  const [doEditContact, setDoEditContact] = useState(false)
  const [doEditAddress, setDoEditAddress] = useState(-1)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false)
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [hasSubmittedPromoCode, setHasSubmittedPromoCode] = useState<boolean>(false)
  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : getClient(
          { id: activeClient.id },
          {
            force: true,
            transform: (body) => {
              return { editClient: body?.client }
            },
            update: { editClient: (_, newValue) => newValue },
          },
        ),
  )
  const [saveClientState, doSaveClient] = useMutation((client: Client) => {
    return updateClient({
      id: activeClient.id,
      client: client,
    })
  })

  const [uploadFileState, doUploadFile] = useMutation((id: number, logo?: File) => {
    return updateClientLogo({
      id: id,
      logo: logo,
    })
  })

  const [applyPromoCodeState, doApplyPromoCode] = useMutation((promoCode: string) =>
    applyPromoCode(
      {
        id: activeClient.id!,
        applyPromoCodeBody: {
          promoCode: promoCode,
        },
      },
      {
        transform: (body) => ({ ...body }),
      },
    ),
  )

  const handlePromoCodeSubmit = (event, values: { promoCode: string }) => {
    doApplyPromoCode(values.promoCode)?.then((response) => {
      setServerErrors(response?.transformed?.messages || {})
      if (response?.transformed?.status === 200) {
        dispatch(
          updateEntities({
            activeClient: (prev) => response?.transformed?.client || prev,
          }),
        )
        setShowPromoCodeModal(false)
        setHasSubmittedPromoCode(true)
      }
    })
  }

  function handleValidLogoFileSubmit(event) {
    if (logoFile) {
      setIsPending(true)
      doUploadFile(activeClient.id, logoFile)?.then((uploadResponse) => {
        setUploadErrors(uploadResponse?.body?.messages?.full_messages || [])
        setIsPending(false)
        if (uploadResponse?.body?.status === 200) {
          doRequest()
        }
      })
    }
  }

  function handleValidSubmit(event, client: Client) {
    setIsPending(true)
    doSaveClient(client)?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      setIsPending(false)
      if (response?.body?.status === 200 && response?.body?.id) {
        setDoEditAddress(-1)
        setDoEditContact(false)
        doRequest()
      }
    })
  }

  const handleChangeLogoFile = (file, accepted) => {
    if (accepted) {
      setLogoFile(file)
    } else {
      setUploadErrors([getFileErrorMessages(file, MAX_FILE_SIZE)])
    }
  }

  const infoFields = [
    {
      title: 'Legal Business Name',
      value: editClient?.businessName,
    },
    {
      title: 'Operating As Name',
      value: editClient?.operatingAs,
    },
  ]

  return (
    <CenterContent>
      <Modal id="promoCodeModal" isOpen={showPromoCodeModal}>
        {applyPromoCodeState.isPending && <LoadingMaskCentered />}
        <AvForm model={activeClient} onValidSubmit={handlePromoCodeSubmit}>
          <ModalHeader>
            <h4 className="mt-0 mb-0">Have a promo code?</h4>
          </ModalHeader>
          <ModalBody>
            <p>If you are a Rotessa partner, or have been given a promo code by a Rotessa partner, enter it here.</p>
            <AvField
              name="promoCode"
              label="Promo Code"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: t('required_field'),
                },
                async: validate,
              }}
              onChange={handleFieldChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-rotessa-secondary"
              onClick={() => {
                setShowPromoCodeModal(false)
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" className="btn-rotessa-primary">
              Submit
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <Modal isOpen={hasSubmittedPromoCode}>
        <ModalHeader>Promo Code Added</ModalHeader>
        <ModalBody>
          <NotificationsText notifications={['The promo code has been added to your account.']} allowHTML />
        </ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={() => setHasSubmittedPromoCode(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-title-box">
        <h4 className="page-title">Settings</h4>
      </div>
      {requestState.isPending ? (
        <Loading />
      ) : (
        <Fade>
          {isPermitted('settings.read_billing_invoices', activeClient) && (
            <>
              <Card>
                <CardBody>
                  <Link to="/client/settings/invoices">
                    <h4 className="header-title mb-3">Invoices</h4>
                  </Link>
                  <InvoicesTable condensedView={true}></InvoicesTable>
                </CardBody>
              </Card>
            </>
          )}
          {isPermitted('settings.users', activeClient) && (
            <>
              <Card>
                <CardBody>
                  <Link to="/client/settings/users">
                    <h4 className="header-title mb-3">Users</h4>
                  </Link>
                  <UsersTable condensedView={true} />
                </CardBody>
              </Card>
            </>
          )}
          {isPermitted('settings.update_business_info', activeClient) && (
            <>
              <AvForm
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                disabled={isPending}
                onValidSubmit={handleValidSubmit}
                model={editClient}
              >
                <Card>
                  <CardBody>
                    <h4 className="header-title mb-3">Primary Contact</h4>
                    {!doEditContact ? (
                      <>
                        <Row>
                          <Col>Phone</Col>
                          <Col>{editClient.phone}</Col>
                        </Row>
                        <Row>
                          <Col>Invoice Email</Col>
                          <Col>{editClient.invoiceEmail}</Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button color="light" onClick={() => setDoEditContact(true)}>
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="phone"
                              label="Phone"
                              type="text"
                              validate={{ tel: validatePhone, async: validate }}
                              onChange={handleFieldChange}
                            />
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="invoiceEmail"
                              label="Invoice Email"
                              type="email"
                              placeholder="john@smith.com"
                              required
                              validate={{ async: validate }}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              onClick={() => setDoEditContact(false)}
                              className="btn-rotessa-secondary min-width mr-2"
                            >
                              Cancel
                            </Button>
                            <Button color="primary" className="min-width">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              </AvForm>

              <AvForm
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                disabled={isPending}
                onValidSubmit={handleValidSubmit}
                model={editClient}
              >
                <Card>
                  <CardBody>
                    {doEditAddress > -1 ? (
                      <>
                        <h4 className="header-title mb-3">
                          {editClient?.addressesAttributes?.[doEditAddress].addressType
                            ?.replace(/([A-Z])/g, ' $1')
                            .trim()}
                        </h4>
                        <Row>
                          <AvField
                            name={`addressesAttributes[${doEditAddress}].id`}
                            type="hidden"
                            required
                            onChange={handleFieldChange}
                          />
                          <Col lg={6}>
                            <AvField
                              name={`addressesAttributes[${doEditAddress}].address1`}
                              label="Street"
                              type="text"
                              required
                              validate={{ async: validate }}
                              onChange={handleFieldChange}
                            />
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name={`addressesAttributes[${doEditAddress}].city`}
                              label="City"
                              type="text"
                              required
                              validate={{ async: validate }}
                              onChange={handleFieldChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <StateProvinceSelect
                              name={`addressesAttributes[${doEditAddress}].provinceCode`}
                              countryCode={activeClient.countryCode}
                              serverErrors={serverErrors}
                              required
                            />
                          </Col>
                          <Col lg={6}>
                            <PostalCodeField
                              name={`addressesAttributes[${doEditAddress}].postalCode`}
                              countryCode={activeClient.countryCode}
                              serverErrors={serverErrors}
                              required
                            />
                          </Col>
                        </Row>
                        <Button onClick={() => setDoEditAddress(-1)} className="btn-rotessa-secondary min-width mr-2">
                          Cancel
                        </Button>
                        <Button color="primary" className="min-width">
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <h4 className="header-title mb-3">Address</h4>
                        {editClient?.addressesAttributes?.map((address, index) => (
                          <>
                            {index > 0 && <hr />}
                            <Row>
                              <Col>{address?.addressType?.split(/(?=[A-Z])/)[0] + ' business address'}</Col>
                              <Col>
                                {address?.address1 +
                                  ' ' +
                                  address?.city +
                                  ', ' +
                                  address.provinceCode +
                                  ' ' +
                                  address.postalCode}
                              </Col>
                              <Col className="max-width-103px">
                                <Button color="light" onClick={() => setDoEditAddress(index)}>
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </>
                        ))}
                      </>
                    )}
                  </CardBody>
                </Card>
              </AvForm>
              <Card>
                <CardBody>
                  <h4 className="header-title mb-3">Information</h4>
                  {infoFields.map((field, i) => (
                    <Row key={i}>
                      <Col>{field.title}</Col>
                      <Col>{field.value}</Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </>
          )}
          {isPermitted('settings.view_settlement_account', activeClient) && (
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Settlement Bank Account</h4>
                {editClient.countryCode === 'US' ? (
                  <>
                    <Row>
                      <Col>Account Type</Col>
                      <Col>{editClient.settlementBankAccount?.bankAccountType}</Col>
                    </Row>
                    <Row>
                      <Col>Routing Number</Col>
                      <Col>{editClient.settlementBankAccount?.routingNumber}</Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col>Transit Number</Col>
                      <Col>{editClient.settlementBankAccount?.transitNumber}</Col>
                    </Row>
                    <Row>
                      <Col>Institution Number</Col>
                      <Col>{editClient.settlementBankAccount?.institutionNumber}</Col>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Col>Account Number</Col>
                  <Col>{editClient.settlementBankAccount?.accountNumber}</Col>
                </Row>
                {isPermitted('settings.update_settlement_account', activeClient) && (
                  <Link to="/client/update_settlement_account">
                    <Button color="light">Update</Button>
                  </Link>
                )}
              </CardBody>
            </Card>
          )}
          {isPermitted('settings.view_billing_account', activeClient) && (
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Billing Bank Account</h4>
                {editClient.countryCode === 'US' ? (
                  <>
                    <Row>
                      <Col>Account Type</Col>
                      <Col>{editClient.billingBankAccount?.bankAccountType}</Col>
                    </Row>
                    <Row>
                      <Col>Routing Number</Col>
                      <Col>{editClient.billingBankAccount?.routingNumber}</Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col>Transit Number</Col>
                      <Col>{editClient.billingBankAccount?.transitNumber}</Col>
                    </Row>
                    <Row>
                      <Col>Institution Number</Col>
                      <Col>{editClient.billingBankAccount?.institutionNumber}</Col>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Col>Account Number</Col>
                  <Col>{editClient.billingBankAccount?.accountNumber}</Col>
                </Row>
                {isPermitted('settings.update_billing_account', activeClient) && (
                  <Link to="/client/update_billing_account">
                    <Button color="light">Update</Button>
                  </Link>
                )}
              </CardBody>
            </Card>
          )}
          {isPermitted('settings.add_promo_code', activeClient) && (
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Add promo Code</h4>
                <Row>
                  <Col>
                    <Button color="light" className="min-width" onClick={() => setShowPromoCodeModal(true)}>
                      Add promo code
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {isPermitted('settings.api_keys', activeClient) && (
            <>
              <Card>
                <CardBody>
                  <Link to="/client/settings/api_keys">
                    <h4 className="header-title mb-3">API Keys</h4>
                  </Link>
                  <ApiKeysTable />
                </CardBody>
              </Card>
            </>
          )}
          {isPermitted('settings.view_account_limits', activeClient) && (
            <Card>
              <CardBody>
                <h4 className="header-title mb-3">Account Limits</h4>
                <Row>
                  <Col>
                    Customer Default Monthly Limit
                    <InfoPopover popoverText="The total dollar amount allowed to be withdrawn from a single customer in a month." />
                  </Col>
                  <Col>
                    {editClient.customerDefaultMonthlyLimit != null && editClient.customerDefaultMonthlyLimit === 0
                      ? 'No limit'
                      : formatCurrency(editClient.customerDefaultMonthlyLimit)}
                  </Col>
                </Row>
                {editClient.customersLimits && editClient.customersLimits.length > 0 && (
                  <>
                    <hr className="mt-1 mb-1" />
                    <Row className="mb-1">
                      <Col>
                        Customer Specific Monthly Limit
                        <InfoPopover popoverText="A special limit above the Customer Default Monthly Limit for a specific approved customer." />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {editClient.customerDefaultMonthlyLimit !== 0 &&
                          editClient.customersLimits?.map(function (limit, i) {
                            return (
                              <Row key={i}>
                                <Col>
                                  <Link to={`customers/${limit.id}`} className="underline_hover">
                                    <span>{limit.name}</span>
                                  </Link>
                                </Col>
                                <Col>
                                  {limit.customerSpecificMonthlyLimit != null &&
                                    formatCurrency(limit.customerSpecificMonthlyLimit)}
                                </Col>
                              </Row>
                            )
                          })}
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mt-2">
                  <Col>
                    For questions regarding limits or limit increases, send an email to our team at support@rotessa.com
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {!activeClient?.newAuthSettingsEnabled && isPermitted('settings.update_logo', activeClient) && (
            <AvForm
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              disabled={isPending}
              onValidSubmit={handleValidLogoFileSubmit}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <h4 className="header-title mb-3">Logo</h4>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      {editClient?.logo?.url && (
                        <span className="ml-2">
                          Currently Uploaded: <img src={editClient.logo.url} alt="" />
                        </span>
                      )}
                      <FileUploader
                        onFileUpload={(file, accepted) => {
                          handleChangeLogoFile(file, accepted)
                        }}
                        accept=".jpeg, .jpg, .png"
                        showPreview={true}
                        maxFiles={1}
                        disabled={isPending}
                        maxSize={MAX_FILE_SIZE}
                      />
                    </Col>
                  </Row>
                  {uploadErrors && (
                    <Row>
                      <Col>
                        <FormServerErrors errors={uploadErrors || []} />
                      </Col>
                    </Row>
                  )}
                  {logoFile && (
                    <Row>
                      <Col>
                        <Button color="primary" className="min-width">
                          Save
                        </Button>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </AvForm>
          )}
          {isReleased('customer_notifications_released') &&
            isPermitted('customers.update_customer_notifications', activeClient) && <NotificationSettingsCard />}
          <Card>
            <CardBody>
              <h4 className="header-title mb-3">Terms and Conditions</h4>
              {editClient?.createdAt && (
                <p>
                  On {formatDateLong(editClient.createdAt)}, {editClient.initialAgreementSigner} agreed to the{' '}
                  <ExternalLink to="http://www.rotessa.com/legal">
                    Rotessa User Agreement, Permission Form Agreement, and Esign Consent
                  </ExternalLink>{' '}
                  on behalf of {editClient.businessName}.
                </p>
              )}
            </CardBody>
          </Card>
        </Fade>
      )}
    </CenterContent>
  )
}
