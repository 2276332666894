// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials  {
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    code?: string;
}

export function CredentialsFromJSON(json: any): Credentials {
    return {
        'email': json['email'],
        'password': json['password'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function CredentialsToJSON(value?: Credentials): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'password': value.password,
        'code': value.code,
    };
}


