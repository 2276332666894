import React, { useEffect, useRef, useState } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useMutation } from 'redux-query-react'
import { Client, NewUser, registerUser } from 'api'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import logo from 'assets/images/logo-blue.png'
import connectCPALogo from 'assets/images/connectCPA-logo.png'
import { ExternalLink } from 'components/ExternalLink'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { gtmEvent } from 'utilities'
import { CenterContentNarrow } from 'components/CenterContent'
import { useDispatch } from 'react-redux'
import { updateEntities } from 'redux-query'
import { CountryRadio } from 'components/CountryRadio'
import { LoadingMaskNoBackground } from 'components/LoadingMask'
import rightArrow from 'assets/images/Right-Arrow-White.svg'

export const CreateAccountPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const promoCode = query.get('promo_code') || ''
  const formRef = useRef<AvForm>()

  const newUser: NewUser = { countryCode: 'CA' }
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [country, setCountry] = useState('CA')
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)

  useEffect(() => gtmEvent('pageview'))

  const [mutationState, doMutation] = useMutation((values: NewUser) => registerUser({ newUser: values }))

  const handleValidSubmit = (_event, values) => {
    values.passwordConfirmation = values.password
    values.confirmSuccessUrl = `${window.location.protocol}//${window.location.host}/signup/start`
    if (promoCode) values.promoCode = promoCode

    doMutation({ ...values })?.then((response) => {
      if (response?.body?.json?.status === 'success') {
        const stashedClientDetails: Client = {
          referredBy: values.referredBy,
          countryCode: values.countryCode,
          promoCode: values.promoCode,
        }
        gtmEvent('createdAccount')
        dispatch(
          updateEntities({
            clientOnboarding: (_) => {
              return {
                state: 'user_info',
                statuses: { referred_by: 'skipped' },
                stashedClientDetails: stashedClientDetails,
              }
            },
            fromCreateAccount: (_) => true,
          }),
        )
      } else {
        const responseErrors = response?.body?.errors || response?.body?.json?.errors
        setServerErrors(responseErrors)
      }
    })
  }

  const changeAgreements = (e) => {
    setCountry(e.target.value)
  }

  return (
    <Row className="onboarding blue-background">
      <Col lg={{ size: 8 }}>
        <img src={logo} height={23} alt="Rotessa" className="ml-3 mt-3 mb-4 ml-lg-5 mt-lg-4" />
        <Row>
          <Col className="onboarding-create-account-middle ml-3 mr-3 ml-lg-0 mr-lg-0">
            <CenterContentNarrow>
              {mutationState.isPending && <LoadingMaskNoBackground />}
              <h3 className="mt-3 mb-1">Sign up for Rotessa</h3>
              <p>
                Create a new account or <Link to="/login">log in.</Link>
              </p>
              <AvForm
                model={newUser}
                onValidSubmit={handleValidSubmit}
                validationEvent="onChange"
                disabled={mutationState.isPending}
                ref={formRef}
              >
                <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
                <Row>
                  <Col>
                    <AvField
                      name="email"
                      label="Email Address"
                      type="text"
                      onChange={handleFieldChange}
                      placeholder="Ex: ex@mple.com"
                      validate={{
                        async: validate,
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                        email: {
                          value: true,
                          errorMessage: 'Please enter a valid email address',
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      onChange={handleFieldChange}
                      placeholder="8+ characters, 1 symbol"
                      validate={{
                        async: validate,
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                        minLength: { value: 8, errorMessage: 'Minimum length: 8' },
                        pattern: {
                          value: /^([\w]+[\W]+|[\W]+[\w]+)/,
                          errorMessage: 'Must contain at least 1 letter or number and 1 symbol',
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CountryRadio
                      label="Choose One"
                      onChange={(e) => changeAgreements(e)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                        async: validate,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvField
                      name="referredBy"
                      label="How did you hear about us?"
                      type="select"
                      validate={{
                        async: validate,
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                      }}
                      value={promoCode ? 'Word of mouth/Referral' : ''}
                      disabled={promoCode}
                    >
                      <option value="" disabled selected>
                        Choose one...
                      </option>
                      <option value="QuickBooks/Xero App Store">QuickBooks/Xero App Store</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Search Engine">Search Engine (Google/Bing)</option>
                      <option value="Word of mouth/Referral">Word of Mouth/Referral</option>
                      <option value="Other">Other</option>
                    </AvField>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="onboarding-agree-link">
                    <AvField
                      type="checkbox"
                      name="avFieldCheckbox"
                      label={
                        <span>
                          By creating and using your Rotessa account, you agree to the{' '}
                          {country === 'CA' && (
                            <ExternalLink to="https://rotessa.com/client-service-agreement-canada/">
                              Rotessa user agreement
                            </ExternalLink>
                          )}
                          {country === 'US' && (
                            <ExternalLink to="https://rotessa.com/client-service-agreement-united-states">
                              Rotessa user agreement
                            </ExternalLink>
                          )}
                          ,{' '}
                          {country === 'CA' && (
                            <ExternalLink to="https://rotessa.com/legal/customer-terms-and-conditions-for-pre-authorized-debit-payments/">
                              pre-authorized debit agreement
                            </ExternalLink>
                          )}
                          {country === 'US' && (
                            <ExternalLink to="https://rotessa.com/legal/customer-terms-and-conditions-for-ach-united-states/">
                              ACH agreement
                            </ExternalLink>
                          )}
                          , and <ExternalLink to="https://rotessa.com/legal/privacy/">privacy policy</ExternalLink>.
                        </span>
                      }
                      validate={{
                        async: validate,
                        required: {
                          value: true,
                          errorMessage: t('required_field'),
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8} xs={12} className="ml-auto d-flex justify-content-end onboarding">
                    <Button
                      disabled={mutationState.isPending}
                      className="next-button w-100 d-flex align-items-center justify-content-center"
                      color="primary"
                    >
                      Get Started
                      <img src={rightArrow} className="ml-1" alt="" />
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CenterContentNarrow>
          </Col>
        </Row>
      </Col>
      <Col lg={4} className="onboarding-create-account-right text-center d-none d-lg-block">
        <div className="onboarding-create-account-text">
          <h1 className="text-light mt-3 mb-3" style={{ fontWeight: 500 }}>
            With Rotessa, we just set it and forget it!
          </h1>
          <Row>
            <Col>
              <h3 className="text-light mt-1 mb-1" style={{ fontWeight: 400 }}>
                Lior Zehtser
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={connectCPALogo} height={24} alt="Connect CPA" />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}
